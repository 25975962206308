.signature-canvas {
    width: 100%;
    height: 20%;
    min-height: 100px;
    max-height: 400px;
}

@keyframes appear {
    from { 
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}