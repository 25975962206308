:root {
  --primary: #003B49;
  --primary-soft: #33A798;
  --primary-font: Roboto, sans-serif;
  --soft-gray: #DADADA
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--primary-font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--primary);
  text-decoration: none;
}

p {
  padding: 0px;
  margin: 0px;
  word-break: break-word;
  color: var(--primary);
}

.swal2-container {
  z-index: 99999999999999 !important;
}